import { updateIntl } from 'react-intl-redux'
import { LocalStorageService } from '..'
import { store } from '../../helpers/store'
import { literals } from '../../literals'
import { ISession } from '../../models'
import { setSessionAction } from '../../store/actions'
import { BaseService } from './base'

class AuthenticationService extends BaseService {
  public async signIn(userName: string, userDomain: string, userPassword: string): Promise<ISession> {
    const session = await this.post('login', { userName, userDomain, userPassword })
    return { ...session, userName }
  }

  public signOut(): void {
    LocalStorageService.remove('session')
    store.dispatch(updateIntl({ locale: 'en-US', messages: literals['en-US'] }))
    store.dispatch(setSessionAction(null))
  }
}

export default new AuthenticationService()
