import styled from 'styled-components'

export default styled.section`
  background: ${ ({ theme }) => theme.colors.grey.light };
  padding: 32px;
  flex: 1;

  > article {
    margin: 0 0 20px 0;
  }

  .cell {
    .archive-icon {
      font-size: 20px;
      color: ${ ({ theme }) => theme.colors.typography.light };
      cursor: pointer;

      &.visible {
        color: ${ ({ theme }) => theme.colors.primary.medium };
      }
    }

    .status {
      align-self: center;
    }
  }

  @media (max-width: 736px) {
    padding: 0 8px 8px;

    > article {
      margin-bottom: 8px;

      > .header {
        display: none;
      }
    }


    .content {
      > article {
        box-shadow: 0 2px 6px 0 rgba(66,74,97,0.12);
        flex-wrap: wrap;
        padding: 12px 16px 16px;

        &:first-of-type {
          margin-top: 8px;
        }

        .cell {
          margin: 0 0 16px;

          &:first-of-type {
            order: 3;
            text-align: right;
          }
          &:last-of-type {
            order: 1;
            align-self: center;
            height: 22px;
            justify-content: center;
          }
          &:nth-child(2) {
            order: 2;
            align-self: center;
            text-align: center;
            font-size: 16px;
          }
          &:nth-child(3) {
            order: 4;
            font-size: 16px;
          }
          &:nth-child(4) {
            order: 7;
            margin-bottom: 0;
            max-width: 100%;

            > div {
              max-width: 100%;
            }
          }
          &:nth-child(5) {
            order: 5;
          }
          &:nth-child(6) {
            order: 6;
          }

          .track-file {
            max-width: 100%;
          }
        }
      }
    }
  }
`
