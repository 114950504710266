import styled from 'styled-components'

export default styled.article`
  position: absolute;
  display: none;
  flex-direction: column;
  left: -32px;
  right: -32px;
  top: 32px;
  background: white;
  z-index: 50;
  margin: auto;
  padding: 14px 16px;
  box-shadow: 0 2px 16px 1px rgba(66,74,97,0.15);

  + .overlay {
    display: none;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 26px 0 0;
      color: ${ ({ theme }) => theme.colors.typography.darkest };
      font-size: 16px;
      font-family: ${ ({ theme }) => theme.fontFamily };
    }

    .download {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 auto;
      width: 40px;
      height: 40px;
      border: 1px solid ${ ({ theme }) => theme.colors.typography.medium };
      font-size: 22px;
      color: ${ ({ theme }) => theme.colors.typography.medium };
      border-radius: 50%;
      flex-shrink: 0;
    }
  }

  .content {
    .field:not(:last-child) {
      margin: 0 0 5px;
    }
  }

  @media (max-width: 736px) {
    position: fixed;
    z-index: 1000;
    top: 30vh;
    right: 0;
    left: 0;
    max-width: 80vw;
    height: auto;
    margin: auto;
    box-shadow: none;
    border-radius: 2px;

    + .overlay {
      z-index: 900;
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      opacity: 0.7;
      background: ${ ({ theme }) => theme.colors.typography.darkest };
    }

    .header {
      .download {
        display: none;
      }
    }
  }
`
