import { File, FileType, Icon } from '@valudio/ui'
import React, { useContext, useState } from 'react'
import { NotificationContext } from '../../../context/notfication'
import { extensionToIconExtension } from '../../../helpers/file'
import { IFile, ITrackFile } from '../../../models'
import { TrackService } from '../../../services'
import FileModal from '../FileModal'
import FilePreview from '../FilePreview'
import Styled from './styles'

interface IProps {
  trackFile: ITrackFile
  className?: string
  isDisabled?: boolean
}

const TrackFile: React.FC<IProps> = ({ trackFile, className, isDisabled }) => {
  const { setNotification } = useContext(NotificationContext)
  const [ file, setFile ] = useState<IFile>(null)
  const [ isModalVisible, setIsModalVisible ] = useState(false)
  const [ isMobilePreviewVisible, setIsMobilePreviewVisible ] = useState(false)
  const isMobileView = window.screen.width <= 736
  const isFileDataEmpty = !file || !file.data
  const extension = !!trackFile && extensionToIconExtension(trackFile.extension)

  const handleFetchFile = async () => {
    if (!!file) return
    try {
      const result = await TrackService.fetchFile(trackFile.fileId)
      setFile(result)
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error)
      setNotification(error)
    }
  }

  const handleMouseOver = async () => {
    if (isMobileView) return
    await handleFetchFile()
  }

  const handleClick = async () => {
    if (isFileDataEmpty && !isMobileView) return
    else if (isFileDataEmpty && isMobileView) await handleFetchFile()
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    if (isFileDataEmpty) return
    setFile(null)
    setIsModalVisible(false)
    setIsMobilePreviewVisible(false)
  }

  const handleOpenFilePreview = () => {
    if (isMobileView) setIsMobilePreviewVisible(!isMobilePreviewVisible)
  }

  return (
    <Styled onMouseOver={ handleMouseOver } className={ className || '' }>
      <File
        className="file"
        name={ trackFile.name }
        type={ extension.toLowerCase() as FileType }
        onClick={ handleClick }
      />
      <Icon className="io-icon" icon={ trackFile.type === 'outbound' ? 'sent' : 'received' }/>
      <FileModal
        isHidden={ !isModalVisible || isFileDataEmpty }
        file={ file }
        format={ trackFile.format }
        onClose={ handleModalClose }
        onOpenFileDetail={ handleOpenFilePreview }
      />
      <FilePreview
        className={`preview ${ isMobileView ? 'mobile' : '' }`}
        trackFile={ trackFile }
        file={ file }
        isHidden={ isDisabled || isFileDataEmpty || (isMobileView && !isMobilePreviewVisible) }
        onClose={ handleOpenFilePreview }
      />
    </Styled>
  )
}

export default TrackFile
