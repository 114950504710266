import { formatArrayParam } from '../../helpers/http-query-param'
import { Entity, Partner } from '../../models'
import { BaseService } from './base'

class FilterService extends BaseService {
  public getFilterData(entities?: Entity[], partners?: Partner[]): Promise<any> {
    return this.get(
      'filters?' +
      `${ formatArrayParam(entities, 'entity') }` +
      `${ formatArrayParam(partners, 'partner') }`
    )
  }
}

export default new FilterService()
