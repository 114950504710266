import styled from 'styled-components'

export default styled.div`
  width: 100%;

  .field {
    width: 100%;

    .input {
      width: 100%;
      max-width: 100%;
    }
  }
`
