export enum UiTypeKeys {
  SET_IS_MENU_EXPANDED = 'SET_IS_MENU_EXPANDED'
}

export interface ISetIsMenuExpanded {
  type: typeof UiTypeKeys.SET_IS_MENU_EXPANDED
  payload: boolean
}

export type UiActionTypes =
  | ISetIsMenuExpanded
