import { DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import routeNames from '../navigation/routeNames'
import { LocalStorageService } from '../services'
import { setSessionAction } from '../store/actions'
import { SessionState, ThemeState } from '../store/states'
import { Routable } from './routable'

interface IProps extends DispatchProp<AnyAction> {
  session: SessionState
  theme: ThemeState
}

export abstract class NonAuthenticatedRoutable<P extends IProps, S = {}> extends Routable<P, S> {
  public componentDidMount(): void {
    const { dispatch, theme } = this.props
    const activeSession = LocalStorageService.get(`session`)

    if (!theme) this.reset(routeNames.splash, null, true)
    activeSession
      ? this.reset(routeNames.home)
      : dispatch(setSessionAction(null))
  }

  public componentDidUpdate(): void {
    if (this.props.session && this.props.session.guid) this.reset(routeNames.home)
  }
}
