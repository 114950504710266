import React, { ReactNode } from 'react'

export function dataToEdifactContent(data: string): ReactNode {
  const decodedString = atob(data)
  const replacedScapedString = decodedString.replace('?\'', '?__?')
  const replacedString = replacedScapedString.replace(/'/gm , '\'<br/>')
  const finalString = replacedString.replace('?__?', '?\'')
  return <div className="edifact" dangerouslySetInnerHTML={ { __html: finalString } } />
}

export function extensionToIconExtension(extension: string, format?: string): string {
  let iconExtension

  if (extension.toLowerCase() === 'pdf' || (!!format && format.toLowerCase() === 'pdf')) {
    iconExtension = 'pdf'
  } else if (extension.toLowerCase() === 'html' || (!!format && format.toLowerCase() === 'html')) {
    iconExtension = 'html'
  } else if (
    extension.toLowerCase() === 'jpg' ||
    extension.toLowerCase() === 'jpeg' ||
    extension.toLowerCase() === 'png' ||
    extension.toLowerCase() === 'tif' ||
    extension.toLowerCase() === 'tiff' ||
    (!!format && format.toLowerCase() === 'image')
  ) {
    iconExtension = 'img'
  } else if (
    extension.toLowerCase() === 'edi' ||
    (!!format && format.toLowerCase() === 'edifact') ||
    extension.toLowerCase() === 'p7m'
  ) {
    iconExtension = 'edi'
  } else {
    iconExtension = extension.toLocaleLowerCase()
  }

  return iconExtension
}
