import { IUiState } from '../states'
import { UiActionTypes, UiTypeKeys } from '../types'

const initialState: IUiState = {
  isMenuExpanded: undefined
}

export default (state: IUiState = initialState, action: UiActionTypes): IUiState => {
  switch (action.type) {
    case UiTypeKeys.SET_IS_MENU_EXPANDED:
      return { ...state, isMenuExpanded: action.payload }
    default:
      return state
  }
}
