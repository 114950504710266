import { Snackbar } from '@valudio/ui'
import React, { useContext } from 'react'
import { NotificationContext } from '../../../context/notfication'
import Styled from './styles'

const Notification: React.FC = ({  }) => {
  const { notification } = useContext(NotificationContext)

  if (!notification) return null
  return (
    <Styled>
      <Snackbar type="error" message={ notification } />
    </Styled>
  )
}

export default Notification
