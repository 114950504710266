import styled from 'styled-components'

export default styled.header`
  width: 100%;
  box-sizing: border-box;
  padding: 24px 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .logo {
    display: none;
    width: 38px;
    height: auto;
    margin-right: 14px;
  }

  h2.title {
    flex: 1;
    margin: 0;
    font-size: 22px;
    font-weight: normal;
    color: ${ ({ theme }) => theme.colors.typography.darkest };
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .count {
      font-size: 14px;
      margin-left: 12px;
      color: ${ ({ theme }) => theme.colors.typography.light };
    }

    @media (max-width: 736px) {
      justify-content: space-between;

      .count {
        display: none;
      }
    }
  }

  .filter-visibility {
    display: none;
    justify-content: center;
    align-items: center;
    bottom: -20px;
    right: 32px;
    font-size: 24px;
    color: ${ ({ theme }) => theme.colors.typography.medium };
    height: 40px;
    width: 40px;
    background: ${ ({ theme }) => theme.colors.white };
    border: 1px solid ${ ({ theme }) => theme.colors.grey.dark };
    cursor: pointer;
    border-radius: 50%;
  }

  @media (max-width: 736px) {
    padding: 16px 8px;
    background: ${ ({ theme }) => theme.colors.grey.light };
    font-weight: bold;
    min-height: 74px;

    .logo {
      display: inline-block;
      font-size: 24px;
      color: ${ ({ theme }) => theme.colors.typography.medium };
      text-align: center;
    }

    .filter-visibility {
      font-weight: normal;
      display: flex;

      &.close {
        background: transparent;
        border: none;
      }
    }
  }
`
