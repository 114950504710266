import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { AuthenticationService } from '.'
import { updateQueryStringParameter } from '../../helpers/http-query-param'
import { store } from '../../helpers/store'

const apiUrl = process.env.REACT_APP_API_URL

export abstract class BaseService {
  public axiosInstance: AxiosInstance
  private boundResponse = this.handleResponse.bind(this)
  private boundResponseError = this.handleResponseError.bind(this)

  constructor() {
    this.axiosInstance = axios.create({
      auth: { username: 'valudio', password: 'Xilofono23%' },
      headers: { 'Content-Type': 'application/json' }
    })
    this.axiosInstance.interceptors.response.use(this.boundResponse, this.boundResponseError)
  }

  public async get(endpoint: string): Promise<any> {
    return this.buildRequest(endpoint)
  }

  public async post(endpoint: string, body: any): Promise<any> {
    return this.buildRequest(endpoint, 'POST', body)
  }

  public async put(endpoint: string, body?: any): Promise<any> {
    return this.buildRequest(endpoint, 'PUT', body)
  }

  public async delete(endpoint: string): Promise<any> {
    return this.buildRequest(endpoint, 'DELETE')
  }

  public async patch(endpoint: string, body?: any): Promise<any> {
    return this.buildRequest(endpoint, 'PATCH', body)
  }

  private async buildRequest(endpoint: string, method: Method = 'GET', body?: any): Promise<any> {
    const state = store.getState()
    const hasCredentials = store && state && !!state.session
    const { userName, guid } = hasCredentials && state.session
    const config: AxiosRequestConfig = { method, baseURL: apiUrl }

    switch (method) {
      case 'PUT':
        return this.axiosInstance.put(endpoint, body, config)
      case 'POST':
        if (hasCredentials) body = { ...body, userId: userName, guid }
        return this.axiosInstance.post(endpoint, body, config)
      case 'DELETE':
        return this.axiosInstance.delete(endpoint, config)
      case 'PATCH':
        return this.axiosInstance.patch(endpoint, body, config)
      default:
        if (hasCredentials)         {
          endpoint = updateQueryStringParameter(endpoint, 'userId', userName)
          endpoint = updateQueryStringParameter(endpoint, 'guid', guid)
        }
        return this.axiosInstance.get(endpoint, config)
    }
  }

  private handleResponse(response: any): AxiosResponse<any> {
    return response.data
  }

  private async handleResponseError(error: AxiosError): Promise<AxiosError | any> {
    if (error.response && error.response.status === 401) {
      // tslint:disable-next-line: no-console
      console.log('Signing out')
      AuthenticationService.signOut()
      const errorMsg = 'User credentials invalid'
      throw errorMsg
    } else {
      throw error.response && error.response.statusText ? error.response.statusText : error.message
    }
  }
}
