import { ThemeState } from '../states'
import { ThemeActionTypes, ThemeTypeKeys } from '../types'

const initialState: ThemeState = null

export default (state: ThemeState = initialState, action: ThemeActionTypes): ThemeState => {
  switch (action.type) {
    case ThemeTypeKeys.SET_THEME:
      return action.payload
    default:
      return state
  }
}
