import * as Aviator from 'aviator'
import React from 'react'
import { configurePageRenderer } from '../helpers/navigation'
import HomePage from '../pages/Home'
import SignInPage from '../pages/SignIn'
import SplashPage from '../pages/Splash'
import routeNames from './routeNames'

const pages = {
  [routeNames.splash]: configurePageRenderer(<SplashPage />),
  [routeNames.signIn]: configurePageRenderer(<SignInPage />),
  [routeNames.home]: configurePageRenderer(<HomePage />)
}

const routes = {
  target: pages,
  [`/${ routeNames.splash }`]: routeNames.splash,
  [`/${ routeNames.signIn }`]: routeNames.signIn,
  [`/${ routeNames.home }`]: routeNames.home
}

export function initializeRouting(): void {
  Aviator.setRoutes(routes)
  Aviator.dispatch()
}
