import { Button, Field, Icon, Input } from '@valudio/ui'
import { autobind } from 'core-decorators'
import React, { ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { updateIntl } from 'react-intl-redux'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { formatTheme } from '../../helpers/session'
import { literals } from '../../literals'
import { NonAuthenticatedRoutable } from '../../models'
import { AuthenticationService, LocalStorageService } from '../../services'
import { setSessionAction, setThemeAction } from '../../store/actions'
import { IStoreState, SessionState, ThemeState } from '../../store/states'
import Styled from './styles'

interface IProps {
  session: SessionState
  theme: ThemeState
}

interface IState {
  username: string
  password: string
  domain: string
  error: Error
}

type Props = IProps & DispatchProp<AnyAction> & WrappedComponentProps

class SignInPage extends NonAuthenticatedRoutable<Props, IState> {
  private get errorMessage(): ReactNode {
    const { error } = this.state
    if (!error) return null
    return (
      <div className="error">
        <Icon icon="atention" className="icon" />
        <span>{ error.message }</span>
      </div>
    )
  }

  private get logo(): ReactNode {
    const theme = this.props.theme
    const themelogo = theme && theme.logo

    if (!theme) return null
    if (themelogo) return <img src={ themelogo } alt="Logo"/>
    return <img src="assets/images/logo-redsol.svg" alt="Redsol"/>
  }

  private get domainField(): ReactNode {
    const { intl, theme } = this.props
    const domain = theme && theme.domain
    if (domain) return null
    return (
      <Field label={ intl.formatMessage({ id: 'domain'  }) } className="field">
        <Input
          className="input"
          placeholder={ intl.formatMessage({ id: 'introduceDomain'  }) }
          onChange={ this.handleDomainChange }
        />
      </Field>
    )
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      domain: '',
      error: null
    }
  }

  public render(): ReactNode {
    const { intl } = this.props
    const { error } = this.state
    return (
      <Styled>
        <section className="container">
          <div className="column form">
            <header className="logo">
              { this.logo }
            </header>
            <Field label={ intl.formatMessage({ id: 'name'  }) } className="field">
              <Input
                className="input"
                placeholder={ intl.formatMessage({ id: 'introduceName'  }) }
                onChange={ this.handleUsernameChange }
              />
            </Field>
            <Field label={ intl.formatMessage({ id: 'password'  }) } className="field">
              <Input
                className="input"
                type="password"
                placeholder={ intl.formatMessage({ id: 'introducePassword'  }) }
                onChange={ this.handlePasswordChange }
              />
            </Field>
            { this.domainField }
            { this.errorMessage }
            <Button className="button" onClick={ this.handleSignIn } isDisabled={ !!error }>
              { intl.formatMessage({ id: 'login' }) }
            </Button>
          </div>
          <div className="column image" />
        </section>
      </Styled>
    )
  }

  @autobind
  private handleUsernameChange(username: string): void {
    this.setState({ username, error: null })
  }

  @autobind
  private handlePasswordChange(password: string): void {
    this.setState({ password, error: null })
  }

  @autobind
  private handleDomainChange(domain: string): void {
    this.setState({ domain, error: null })
  }

  @autobind
  private async handleSignIn(): Promise<void> {
    const { username, password, domain } = this.state
    const userDomain = domain || this.props.theme.domain
    const dispatch = this.props.dispatch

    try {
      const session = await AuthenticationService.signIn(username, userDomain, password)

      if (!session) throw new Error('No session provided')
      LocalStorageService.set('session', JSON.stringify(session))
      dispatch(setSessionAction(session))
      dispatch(updateIntl({ locale: session.language, messages: literals[session.language] }))
      dispatch(setThemeAction(formatTheme(session.theme)))
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error)
      this.setState({ error })
    }
  }
}

const mapStateToProps = ({ session, theme }: IStoreState) => ({
  session,
  theme
})

export default injectIntl(
  connect(mapStateToProps)(SignInPage)
)
