import { SessionState } from '../states'

export enum SessionTypeKeys {
  SET_SESSION = 'SET_SESSION'
}

export interface ISetSessionAction {
  type: typeof SessionTypeKeys.SET_SESSION
  payload: SessionState
}

export type SessionActionTypes =
  | ISetSessionAction
