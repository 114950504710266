import { ITheme } from '../../models'

export enum ThemeTypeKeys {
  SET_THEME = 'SET_THEME'
}

export interface ISetThemeAction {
  type: typeof ThemeTypeKeys.SET_THEME
  payload: ITheme
}

export type ThemeActionTypes =
  | ISetThemeAction
