import { Icon, IOption, ITableColumn } from '@valudio/ui'
import { autobind } from 'core-decorators'
import React, { ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { DateTimeFilter, FilterActions, MultiSelectFilter, PageTitle, TextFilter, TracksTable } from '../../components'
import { NotificationContext } from '../../context/notfication'
import { formatInputDate } from '../../helpers/date'
import { AuthenticatedRoutable, IFilter, TrackPage } from '../../models'
import { FilterService, TrackService } from '../../services'
import { setIsMenuExpanded } from '../../store/actions'
import { IStoreState, SessionState } from '../../store/states'
import Styled from './styles'

interface IProps {
  session: SessionState
  isMenuExpanded: boolean
}

interface IState {
  page: TrackPage
  filters: IFilter
  selectedFilters: IFilter
  areArchivedTracksHidden: boolean
  tableColumns: ITableColumn[]
  order: string
  orderBy: string
  areFiltersVisible: boolean
  isFetching: boolean
}

type Props = IProps & DispatchProp<AnyAction> & WrappedComponentProps

class HomePage extends AuthenticatedRoutable<Props, IState> {
  public static contextType = NotificationContext
  private get isAnyFieldWithError(): boolean {
    const errorInputs = document.querySelectorAll('input:invalid')
    return !!errorInputs && !!errorInputs.length
  }

  private get tracksCount(): ReactNode {
    const intl = this.props.intl
    const page = this.state.page
    if (!page || (!!page && !page.pagination.totalItems)) return null
    return (
      <span className="count">
        ( { `${ page.pagination.totalItems } ${ intl.formatMessage({ id: 'tracksFound' }) }` } )
      </span>
    )
  }

  constructor(props: Props) {
    super(props)
    const intl = props.intl
    const today = new Date()
    const initialDateFrom = formatInputDate(today.setMonth(today.getMonth() - 3))
    const isMobileScreen = window.screen.width <= 736

    this.state = {
      page: null,
      filters: { entities: [], partners: [], messageType: [], status: [], subPartners: [] },
      selectedFilters: {
        entities: [],
        partners: [],
        messageType: [],
        messageRef: '',
        status: [],
        subPartners: [],
        dateFrom: initialDateFrom
      },
      areArchivedTracksHidden: false,
      tableColumns: [
        { label: '', key: 'archived', style: { flex: isMobileScreen ? '0 1 30px' : 0.2 }, isHidden: true },
        {
          label: intl.formatMessage({ id: 'partnerName' }),
          key: 'partner',
          sort: 'INACTIVE',
          onClick: this.handleColumnSort.bind(this, 'partner')
        },
        {
          label: intl.formatMessage({ id: 'description' }),
          key: 'description',
          sort: 'INACTIVE',
          style: { flex: isMobileScreen ? '0 1 100%' : 4 },
          onClick: this.handleColumnSort.bind(this, 'description')
        },
        {
          label: intl.formatMessage({ id: 'exchanges' }),
          key: 'exchanges',
          style: { overflow: 'visible', flex: isMobileScreen ? '0 1 100%' : 2 }
        },
        {
          label: intl.formatMessage({ id: 'date' }),
          key: 'date',
          sort: 'INACTIVE',
          style: { flex: isMobileScreen ? '0 1 50%' : 'inherit' },
          onClick: this.handleColumnSort.bind(this, 'date')
        },
        {
          label: intl.formatMessage({ id: 'messageType' }),
          key: 'messageType',
          style: { flex: isMobileScreen ? '0 1 50%' : 2 },
          sort: 'INACTIVE',
          onClick: this.handleColumnSort.bind(this, 'messageType')
        },
        { label: '', key: 'status', style: { flex: isMobileScreen ? '0 1 30px' : 0.2 } }
      ],
      order: '',
      orderBy: '',
      areFiltersVisible: true,
      isFetching: false
    }
  }

  public async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.fetchTracks(null, null, this.state.areArchivedTracksHidden)
    await this.fetchFilters(this.state.selectedFilters)
    this.updateArchiveVisibility()
  }

  public render(): ReactNode {
    const { intl, session, isMenuExpanded } = this.props
    const {
      page,
      tableColumns,
      filters,
      areArchivedTracksHidden,
      selectedFilters,
      isFetching,
      areFiltersVisible
    } = this.state
    const minFromDate = new Date()
    const initialFromDate = new Date()
    minFromDate.setFullYear(initialFromDate.getFullYear() - 1)
    initialFromDate.setMonth(initialFromDate.getMonth() - 3)
    initialFromDate.setHours(0)
    initialFromDate.setMinutes(0)
    initialFromDate.setSeconds(0)

    return (
      <>
        <Styled>
          <PageTitle onClick={ this.toggleResponsiveMenuVisibility }>
            { intl.formatMessage({ id: 'trackingList' }) }
            { this.tracksCount }
            <Icon
              className="filter-visibility"
              icon={ !areFiltersVisible ? 'close' : 'filter' }
              onClick={ this.toggleFiltersVisibility }
            />
          </PageTitle>
          <section className={ `filters ${ !areFiltersVisible ? 'closed' : '' }` }>
            <section className="container">
              <MultiSelectFilter
                label={ intl.formatMessage({ id: 'myEntities' }) }
                items={ filters.entities }
                placeholder={ intl.formatMessage({ id: 'selectEntity' }) }
                onChange={ this.handleFilterChange.bind(this, 'entities') }
              />
              <MultiSelectFilter
                label={ intl.formatMessage({ id: 'messageType' }) }
                items={ filters.messageType }
                placeholder={ intl.formatMessage({ id: 'selectMessageType' }) }
                onChange={ this.handleFilterChange.bind(this, 'messageType') }
              />
              <TextFilter
                label={ intl.formatMessage({ id: 'messageRef' }) }
                placeholder={ intl.formatMessage({ id: 'introduceMessageRef' }) }
                onChange={ this.handleFilterChange.bind(this, 'messageRef') }
              />
              <MultiSelectFilter
                label={ intl.formatMessage({ id: 'partners' }) }
                items={ filters.partners }
                placeholder={ intl.formatMessage({ id: 'selectPartner' }) }
                onChange={ this.handleFilterChange.bind(this, 'partners') }
              />
              <MultiSelectFilter
                label={ intl.formatMessage({ id: 'subPartners' }) }
                items={ filters.subPartners }
                placeholder={ intl.formatMessage({ id: 'selectSubPartner' }) }
                onChange={ this.handleFilterChange.bind(this, 'subPartners') }
              />
              <DateTimeFilter
                initialValue={ initialFromDate.toISOString() }
                label={ intl.formatMessage({ id: 'dateFrom' }) }
                minDate={ minFromDate.toISOString() }
                onChange={ this.handleFilterChange.bind(this, 'dateFrom') }
              />
              <DateTimeFilter
                label={ intl.formatMessage({ id: 'to' }) }
                minDate={ selectedFilters.dateFrom }
                onChange={ this.handleFilterChange.bind(this, 'dateTo') }
              />
              <MultiSelectFilter
                label={ intl.formatMessage({ id: 'status' }) }
                items={ filters.status }
                placeholder={ intl.formatMessage({ id: 'selectStatus' }) }
                onChange={ this.handleFilterChange.bind(this, 'status') }
              />
            </section>
            <FilterActions
              initialArchiveValue={ !areArchivedTracksHidden }
              onSearch={ this.fetchTracks }
              onArchiveChange={ this.handleChangeArchiveVisibility }
              isArchiveHidden={ !!session && !session.hideTracks }
              areActionsDisabled={ this.isAnyFieldWithError }
            />
            <Icon
              className="filter-visibility"
              icon={ areFiltersVisible ? 'up' : 'filter' }
              onClick={ this.toggleFiltersVisibility }
            />
          </section>
          <TracksTable
            page={ page }
            columns={ tableColumns }
            isLoading={ isFetching }
            onChangeTrackVisibility={ this.handleChangeTrackVisibility }
            onPageChange={ this.handlePageChange }
          />
        </Styled>
        <div
          className={`menu-overlay ${ isMenuExpanded ? 'visible' : '' }`}
          onClick={ this.toggleResponsiveMenuVisibility }
        />
      </>
    )
  }

  @autobind
  private toggleFiltersVisibility(): void {
    this.setState({ areFiltersVisible: !this.state.areFiltersVisible })
  }

  @autobind
  private toggleResponsiveMenuVisibility(): void {
    const { isMenuExpanded: isMenuVisible, dispatch } = this.props
    dispatch(setIsMenuExpanded(!isMenuVisible))
  }

  @autobind
  private async fetchTracks(
      orderBy?: string, order?: string, hideTracks?: boolean, pageNumber?: number
  ): Promise<void> {
    const isMobileScreen = window.screen.width <= 736
    this.setState({ isFetching: true })

    try {
      const { selectedFilters, areFiltersVisible, areArchivedTracksHidden } = this.state
      const sholdHideTracks = typeof hideTracks === 'boolean' ? hideTracks : areArchivedTracksHidden
      if (isMobileScreen && !areFiltersVisible) this.toggleFiltersVisibility()

      const trackPage = await TrackService.fetchTrackPage(
        selectedFilters.entities,
        selectedFilters.partners,
        selectedFilters.messageType,
        selectedFilters.status,
        selectedFilters.subPartners,
        selectedFilters.messageRef,
        !!selectedFilters.dateFrom ? (new Date(selectedFilters.dateFrom)).toISOString() : null,
        !!selectedFilters.dateTo ? (new Date(selectedFilters.dateTo)).toISOString() : null,
        orderBy,
        order,
        sholdHideTracks,
        pageNumber,
      )
      this.setState({ page: trackPage, isFetching: false })
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error)
      this.setState({ isFetching: false })
      this.context.setNotification(error)
    }
  }

  @autobind
  private async fetchFilters(selectedFilters: IFilter): Promise<void> {
    try {
      const { entities, partners } = selectedFilters
      const filters = await FilterService.getFilterData(entities, partners)
      this.setState({ filters })
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error)
      this.context.setNotification(error)
    }
  }

  private async handleFilterChange(key: string, value: any): Promise<void> {
    let updateFilter
    switch (key) {
      case 'dateTo':
      case 'dateFrom':
      case 'messageRef':
        updateFilter = value
        break
      default:
        updateFilter = value.map((v: IOption) => ({ key: v.id, value: v.label }))
    }

    const selectedFilters = { ...this.state.selectedFilters, [key]: updateFilter }
    this.setState({ selectedFilters })
    if (key === 'entities' || key === 'partners') await this.fetchFilters(selectedFilters)
  }

  private async handleColumnSort(key: string): Promise<void> {
    try {
      const tableColumns = [...this.state.tableColumns]
      const column = tableColumns.find(c => c.key === key)
      let orderBy
      let order

      if (!!column.sort && column.sort === 'INACTIVE') {
        column.sort = 'ASC'
        orderBy = key
      } else if (!!column.sort) {
        column.sort = column.sort === 'ASC' ? 'DESC' : 'INACTIVE'
        orderBy = column.sort !== 'INACTIVE' && key
      }

      order = column.sort !== 'INACTIVE' && column.sort
      tableColumns.forEach(c => c.sort = !!c.sort && c.key !== key ? 'INACTIVE' : c.sort)
      this.setState({ tableColumns, orderBy, order })
      await this.fetchTracks(orderBy, order)
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error)
    }
  }

  @autobind
  private async handlePageChange(pageNumber: number): Promise<void> {
    const { orderBy, order } = this.state
    const hideTracks = this.state.areArchivedTracksHidden
    await this.fetchTracks(orderBy, order, hideTracks, pageNumber)
  }

  @autobind
  private async handleChangeTrackVisibility(trackId: string): Promise<void> {
    try {
      const track = this.state.page.items.find(i => i.trackId === trackId)
      const currentVisibility = track.visible
      const updatedTracks = [...this.state.page.items]
      await TrackService.changeTrackVisibility(trackId)

      updatedTracks.forEach(i => i.visible = i.trackId === trackId ? !currentVisibility : i.visible)
      this.setState({ page: { pagination: this.state.page.pagination, items: updatedTracks }  })
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error)
      this.context.setNotification(error)
    }
  }

  @autobind
  private async handleChangeArchiveVisibility(): Promise<void> {
    const { areArchivedTracksHidden: areArchivedTracksVisible, page } = this.state
    await this.fetchTracks(null, null, !areArchivedTracksVisible, page.pagination.pageNumber)
    this.setState({ areArchivedTracksHidden: !areArchivedTracksVisible })
  }

  @autobind
  private updateArchiveVisibility(): void {
    const session = this.props.session
    const tableColumns = [...this.state.tableColumns]

    tableColumns[0].isHidden = !!session && !session.hideTracks
    this.setState({ tableColumns })
  }
}

const mapStateToProps = ({ session, ui }: IStoreState): IProps => ({
  session,
  isMenuExpanded: ui.isMenuExpanded
})

export default injectIntl(
  connect(mapStateToProps)(HomePage)
)
