import { ILanguage, Menu as UIMenu, MenuItem } from '@valudio/ui'
import * as Aviator from 'aviator'
import React, { useContext } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { updateIntl } from 'react-intl-redux'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationContext } from '../../../context/notfication'
import { getTheme } from '../../../helpers/session'
import { literals } from '../../../literals'
import routeNames from '../../../navigation/routeNames'
import { AuthenticationService, LanguageService } from '../../../services'
import { IStoreState } from '../../../store/states'

const Menu: React.FC<WrappedComponentProps> = ({ intl }) => {
  const session = useSelector((x: IStoreState) => x.session)
  const dispatch = useDispatch()
  const isMenuExpanded = useSelector((x: IStoreState) => x.ui.isMenuExpanded)
  const { setNotification } = useContext(NotificationContext)
  const isMobileViewWidth = window.screen.width <= 736
  const languages: ILanguage[] = [
    { locale: 'en-US', label: intl.formatMessage({ id: 'english' }) },
    { locale: 'fr-FR', label: intl.formatMessage({ id: 'french' }) },
    { locale: 'es-ES', label: intl.formatMessage({ id: 'spanish' }) }
  ]
  const isHomeActive = Aviator.getCurrentURI().replace('/', '') === routeNames.home

  const handleLanguageClick = async (locale: string) => {
    try {
      await LanguageService.changeLanguage(locale)
      dispatch(updateIntl({ locale, messages: literals[locale] }))
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error)
      setNotification(error)
    }
  }

  const handleHomeNavigate = () => {
    Aviator.navigate(routeNames.home)
  }

  return (
    <UIMenu
      username={ session && session.userName }
      languages={ languages }
      onLanguageClick={ handleLanguageClick }
      isHidden={ !session }
      logoSrc={ getTheme().logo }
      onSignOut={ AuthenticationService.signOut }
      isExpanded={ isMenuExpanded }
      style={ { width: isMobileViewWidth ? 0 : '64px', zIndex: 1000 } }
    >
      <MenuItem onClick={ handleHomeNavigate } label="Dashboard" icon="dashboard" isActive={ isHomeActive }/>
    </UIMenu>
  )
}

export default injectIntl(Menu)
