import { Spinner } from '@valudio/ui'
import { autobind } from 'core-decorators'
import React, { ReactNode } from 'react'
import { updateIntl } from 'react-intl-redux'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { NotificationContext } from '../../context/notfication'
import { formatLoginTheme, getSubdomain } from '../../helpers/configuration'
import { formatTheme } from '../../helpers/session'
import { literals } from '../../literals'
import { Language, Routable } from '../../models'
import routeNames from '../../navigation/routeNames'
import { ConfigurationService, LocalStorageService } from '../../services'
import { setSessionAction, setThemeAction } from '../../store/actions'
import { IStoreState, SessionState, ThemeState } from '../../store/states'
import Styled from './styles'

interface IProps {
  session: SessionState
  theme: ThemeState
}

type Props = IProps & DispatchProp<AnyAction>

class SplashPage extends Routable<Props> {
  public static contextType = NotificationContext
  public async componentDidMount(): Promise<void> {
    await this.fetchLoginTheme()
    await this.checkBrowserLanguage()
  }

  public componentDidUpdate(): void {
    const { theme, session, dispatch } = this.props
    if (theme) this.navigate(routeNames.signIn)
    if (!session) {
      const activeSession = LocalStorageService.get(`session`)
      if (activeSession) {
        dispatch(setSessionAction(activeSession))
        dispatch(setThemeAction(formatTheme(activeSession.theme)))
        this.navigate(routeNames.home)
      } else {
        this.reset(routeNames.splash)
      }
    }
  }

  public render(): ReactNode {
    return (
      <Styled>
        <Spinner />
      </Styled>
    )
  }

  @autobind
  private async fetchLoginTheme(): Promise<void> {
    const dispatch = this.props.dispatch

    try {
      const subdomain = getSubdomain()
      const loginTheme = await ConfigurationService.getLoginTheme(subdomain)
      if (loginTheme) dispatch(setThemeAction(formatLoginTheme(loginTheme)))
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error)
      this.context.setNotification(error)
    }
  }

  @autobind
  private checkBrowserLanguage(): void {
    const { session, dispatch } = this.props
    const browserLanguage: any = navigator.language || (navigator as any).userLanguage

    if (!!session) return
    if (browserLanguage in Language) {
      dispatch(updateIntl({ locale: browserLanguage, messages: literals[browserLanguage] }))
    } else dispatch(updateIntl({ locale: 'en-US', messages: literals['en-US'] }))
  }
}

const mapStateToProps = ({ session, theme }: IStoreState): IProps => ({
  session,
  theme
})

export default connect(mapStateToProps)(SplashPage)
