import styled from 'styled-components'

export default styled.div`
  > .label {
    font-size: 14px;
    color: ${ ({ theme }) => theme.colors.typography.medium };
    margin-bottom: 6px;
  }

  .multi-select {
    width: 100%;
    box-sizing: border-box;
  }
`
