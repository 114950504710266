import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
  max-width: 210px;

  .file {
    overflow: hidden;
    flex: 1;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .io-icon {
    font-size: 24px;
    margin-left: 5px;
    color: #687494;
  }

  &:hover {
    .preview {
      display: flex;
    }
  }

  @media (max-width: 736px) {
    .preview {
      display: flex;
    }
  }
`
