export const literals: { [key: string]: { [key: string]: string } } =  {
  'en-US': {
    name: 'Name',
    password: 'Password',
    domain: 'Domain',
    introduceName: 'Introduce your name',
    introducePassword: 'Introduce your password',
    introduceDomain: 'Introduce your domain',
    login: 'Login',
    invalidUser: 'User credentials invalid',
    serverError: 'Server error',
    trackingList: 'Tracking list',
    selectEntity: 'Select entity',
    selectMessageType: 'Select message type',
    selectPartner: 'Select partner',
    selectSubPartner: 'Select sub partner',
    selectStatus: 'Select status',
    introduceMessageRef: 'Introduce the message reference',
    search: 'Search',
    myEntities: 'My Entities',
    messageType: 'Message type',
    messageRef: 'Message Ref.',
    partners: 'Partners',
    subPartners: 'Sub Partners',
    dateFrom: 'Date From',
    to: 'To',
    status: 'Status',
    partnerName: 'Partner Name',
    description: 'Description',
    exchanges: 'Exchanges',
    date: 'Date',
    showTracks: 'Show archived tracks',
    dashboard: 'Dashboard',
    english: 'English',
    french: 'French',
    spanish: 'Spanish',
    channel: 'Channel',
    size: 'Size',
    format: 'Format',
    subFormat: 'Sub Format',
    information: 'Information',
    updateDate: 'Update date',
    tracksFound: 'Trackings found',
    noTrackings: 'No trackings found',
    selectAll: 'Select all',
    unSelectAll: 'Unselect all',
    datePlaceholder: 'DD/MM/YYYY 00:00:00'
  },
  'fr-FR': {
    name: 'Nom',
    password: 'Mot de Passe',
    domain: 'Domaine',
    introduceName: 'Entrez votre nom',
    introducePassword: 'Entrez votre mot de passe',
    introduceDomain: 'Entrez votre Domaine',
    login: 'Nom d\'utilisateur',
    invalidUser: 'Combinaison utilisateur/mot de passe invalide',
    serverError: 'Erreur Serveur',
    trackingList: 'Liste de suivi',
    selectEntity: 'Choix de l\'entité',
    selectMessageType: 'Choix du message',
    selectPartner: 'Choix du partenaire',
    selectSubPartner: 'Choix du partenaire',
    selectStatus: 'Choix du statut',
    introduceMessageRef: 'Entrez le terme recherché',
    search: 'Rechercher',
    myEntities: 'Mes entités',
    messageType: 'Type de message',
    messageRef: 'Référence du Message',
    partners: 'Partenaire',
    subPartners: 'Sous Partenaire',
    dateFrom: 'De',
    to: 'A',
    status: 'Statut',
    partnerName: 'Nom du Partenaire',
    description: 'Description',
    exchanges: 'Echanges',
    date: 'Date',
    showTracks: 'Montrez les données masquées',
    dashboard: 'Dashboard',
    english: 'Anglais',
    french: 'Français',
    spanish: 'Espagnol',
    channel: 'Canal',
    size: 'Taille',
    format: 'Format',
    subFormat: 'Sous Format',
    information: 'Informations',
    updateDate: 'Date de mise à jour',
    tracksFound: 'Tracks trouvés',
    noTrackings: 'Pas de track trouvé',
    selectAll: 'Tout sélectionner',
    unSelectAll: 'Tout déselectionner',
    datePlaceholder: 'JJ/MM/AAAA 00:00:00'
  },
  'es-ES': {
    name: 'Nombre',
    password: 'Contraseña',
    domain: 'Dominio',
    introduceName: 'Introduzce tu nombre',
    introducePassword: 'Introduce tu contraseña',
    introduceDomain: 'Introduce tu dominio',
    login: 'Inicio de sesión',
    invalidUser: 'Credenciales de usuario no válidas',
    serverError: 'error de Servidor',
    trackingList: 'Lista de seguimientos',
    selectEntity: 'Seleccione una entidad',
    selectMessageType: 'Seleccionar un tipo de mensaje',
    selectPartner: 'Seleccionar un socio comercial',
    selectSubPartner: 'Seleccionar socio secundario',
    selectStatus: 'Seleccionar estado',
    introduceMessageRef: 'Introduce la referencia del mensaje',
    search: 'Buscar',
    myEntities: 'Mis entidades',
    messageType: 'Tipo de mensaje',
    messageRef: 'Referencia del mensaje',
    partners: 'Socios',
    subPartners: 'Socios secundarios',
    dateFrom: 'Fecha de',
    to: 'A',
    status: 'Estado',
    partnerName: 'Nombre del Socio',
    description: 'Descripción',
    exchanges: 'Intercambios',
    date: 'Fecha',
    showTracks: 'Mostrar tracks archivados',
    dashboard: 'Dashboard',
    english: 'Inglés',
    french: 'Francés',
    spanish: 'Español',
    channel: 'Canal',
    size: 'Tamaño',
    format: 'Formato',
    subFormat: 'Subformato',
    information: 'Información',
    updateDate: 'Fecha de actualización',
    tracksFound: 'Tracks encontrados',
    noTrackings: 'No se ha encontrado ningún track',
    selectAll: 'Seleccionar todo',
    unSelectAll: 'deseleccionar todo',
    datePlaceholder: 'DD/MM/AAAA 00:00:00'
  }
}
