import { DateTime } from '@valudio/ui'
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import Styled from './styles'

interface IProps {
  label: string
  minDate: string
  onChange: (value: string) => void
  initialValue?: string
}

type Props = IProps & WrappedComponentProps

const DateTimeFilter: React.FC<Props> = ({ label, onChange, minDate, initialValue, intl }) => {
  return (
    <Styled className="filter">
      <label className="label">{ label }</label>
      <DateTime
        initialValue={ initialValue }
        onChange={ onChange }
        className="input"
        minDate={ minDate }
        placeholder={ intl.formatMessage({ id: 'datePlaceholder' }) }
      />
    </Styled>
  )
}

export default injectIntl(DateTimeFilter)
