import { DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { formatTheme } from '../helpers/session'
import routeNames from '../navigation/routeNames'
import { LocalStorageService } from '../services'
import { setSessionAction, setThemeAction } from '../store/actions'
import { SessionState } from '../store/states'
import { Routable } from './routable'

export interface IAuthenticatedRoutableProps extends DispatchProp<AnyAction> {
  session: SessionState
}

export abstract class AuthenticatedRoutable<
  P extends IAuthenticatedRoutableProps = IAuthenticatedRoutableProps,
  S = {}
> extends Routable<P, S> {
  public componentDidMount(): void {
    const { session, dispatch } = this.props
    if (session) return

    const activeSession = LocalStorageService.get(`session`)
    if (activeSession) {
      dispatch(setSessionAction(activeSession))
      dispatch(setThemeAction(formatTheme(activeSession.theme)))
    } else {
      this.reset(routeNames.splash)
    }
  }

  public componentDidUpdate(): void {
    const session = this.props.session
    if (!session) this.reset(routeNames.splash, null, true)
  }
}
