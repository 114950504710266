import React, { createContext, useState } from 'react'
import { sleep } from '../helpers/utils'

interface INotificationContext {
  notification?: string
  setNotification: (message: string) => void
}

const NotificationContext = createContext<INotificationContext>({
  notification: undefined,
  setNotification: (message: string) => undefined
})

const NotificationProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState(undefined)

  const handleSetNotification = async (message: string) => {
    setValue(message)
    await sleep(4000)
    setValue(undefined)
  }

  return (
    <NotificationContext.Provider value={{ notification: value, setNotification: handleSetNotification }}>
      { children }
    </NotificationContext.Provider>
  )
}

export { NotificationContext, NotificationProvider }
