import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;

  .label {
    flex: 1;
    font-size: 14px;
    color: ${ ({ theme }) => theme.colors.typography.medium };

    &::after {
      content: ':';
    }
  }

  .value {
    flex: 1;
    font-size: 14px;
    color: ${ ({ theme }) => theme.colors.typography.dark };
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
