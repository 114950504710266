import * as Aviator from 'aviator'
import { PureComponent } from 'react'
import { DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'

export abstract class Routable<
  P extends DispatchProp<AnyAction> = DispatchProp<AnyAction>,
  S = {}
> extends PureComponent<P, S> {
  protected navigate(routeName: string, queryParams?: {}, replaceBase?: boolean): void {
    Aviator.navigate(`${replaceBase ? '/' : ''}${routeName}`, { queryParams })
  }

  protected reset(routeName: string, queryParams?: {}, replaceBase?: boolean): void {
    Aviator.navigate(`${replaceBase ? '/' : ''}${routeName}`, { replace: true, queryParams })
  }

  protected isURIActive(uri: string): boolean {
    const currentURI = Aviator.getCurrentURI()
    return uri === currentURI.slice(1)
  }
}
