import { Button, Toggle } from '@valudio/ui'
import React, { ReactNode } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import Styled from './styles'

interface IProps {
  onSearch: () => void
  onArchiveChange: () => void
  initialArchiveValue: boolean
  isArchiveHidden: boolean
  areActionsDisabled?: boolean
}

type Props = IProps & WrappedComponentProps

// tslint:disable-next-line: max-line-length
const FilterActions: React.FC<Props> = ({ onSearch, onArchiveChange, isArchiveHidden, initialArchiveValue, intl, areActionsDisabled }) => {
  const archiveToggle: ReactNode = !isArchiveHidden && (
    <div className="archive">
      <span className="toggle-label">{ intl.formatMessage({ id: 'showTracks' }) }</span>
      <Toggle initialValue={ initialArchiveValue } onChange={ onArchiveChange  } isDisabled={ areActionsDisabled }/>
    </div>
  )

  return (
    <Styled className="submit">
      <Button onClick={ onSearch } isDisabled={ areActionsDisabled }>
        { intl.formatMessage({ id: 'search' }) }
      </Button>
      { archiveToggle }
    </Styled>
  )
}

export default injectIntl(FilterActions)
