export function formatParam(value: string, param: string): string {
  return !!value ? `&${ param }=${ value }` : ''
}

export function formatArrayParam(values: any[], param: string): string {
  return !!values && !!values.length
    ? `&${ values.map(v => {
      if ((typeof v.value) === 'object') return `${ param }=${ v.value.text }`
      else return `${ param }=${ v.key }`
    }).join('&') }`
    : ''
}

// source: https://stackoverflow.com/a/6021027/1322417
export function updateQueryStringParameter(uri: string, key: string, value: string): string {
  if (uri.endsWith('?')) uri = uri.slice(0, -1)
  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  const separator = uri.indexOf('?') !== -1 ? '&' : '?'
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2')
  } else {
    return uri + separator + key + '=' + value
  }
}
