import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'
import { Menu, Notification, Provider } from '../components'
import { IAviatorDispatchAction } from '../models'
import { store } from './store'

const renderElement = document.getElementById('reds')

export function configurePageRenderer(component: ReactElement<any>): (action: IAviatorDispatchAction) => void {
  return action => {
    return ReactDOM.render((
      <Provider store={ store } >
        <Menu />
        <Notification />
        <component.type { ...component.props } { ...action } />
      </Provider>
    ), renderElement)
  }
}
