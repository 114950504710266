import { Icon } from '@valudio/ui'
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { downloadBase64 } from '../../../helpers/configuration'
import { IFile, ITrackFile } from '../../../models'
import Field from './Field'
import Styled from './styles'

interface IProps {
  trackFile: ITrackFile
  file: IFile
  isHidden?: boolean
  className?: string
  onClose?: () => void
}

type Props = IProps & WrappedComponentProps

// tslint:disable: max-line-length
const FilePreview: React.FC<Props> = ({ file, trackFile, isHidden, className, onClose, intl }) => {
  if (isHidden) return null
  return (
    <>
      <Styled className={ className || '' }>
        <section className="header">
          <span className="title">{ file.name }</span>
          <Icon
            className="download"
            icon="download"
            isHidden={ !file.data }
            onClick={ downloadBase64.bind(undefined, file) }
          />
        </section>
        <section className="content">
          <Field className="field" label={ intl.formatMessage({ id: 'status' }) }>{ trackFile.status.value.text }</Field>
          <Field className="field" label={ intl.formatMessage({ id: 'channel' }) }>{ trackFile.channel }</Field>
          <Field className="field" label={ intl.formatMessage({ id: 'size' }) }>{ trackFile.size }</Field>
          <Field className="field" label={ intl.formatMessage({ id: 'format' }) }>{ trackFile.format }</Field>
          <Field className="field" label={ intl.formatMessage({ id: 'subFormat' }) }>{ trackFile.subFormat }</Field>
          <Field className="field" label={ intl.formatMessage({ id: 'information' }) }>{ trackFile.information }</Field>
          <Field className="field" label={ intl.formatMessage({ id: 'updateDate' }) }>{ trackFile.updateDate }</Field>
        </section>
      </Styled>
      <div className="overlay" onClick={ onClose }/>
    </>
  )
}

export default injectIntl(FilePreview)
