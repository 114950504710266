import styled from 'styled-components'

export default styled.article`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  overflow-y: auto;

  + .menu-overlay {
    display: none;
  }

  .test-text{
    color: ${ ({ theme }) => theme.colors.primary.medium };
  }

  .filters {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 14px 32px 32px;
    box-sizing: border-box;
    position: relative;
    min-height: 272px;

    &.closed {
      padding: 0;
      min-height: 0;

      .container,
      .submit {
        display: none;
      }
    }

    .container {
      width: 100%;
      background: white;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .filter {
        flex: 0 1 calc(25% - 12px);
        max-width: calc(25% - 12px);
        margin: 0 8px;
        margin-bottom: 22px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;

        &:first-of-type,
        &:nth-child(5n) {
          margin-left: 0;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }

        .input {
          width: 100%;
        }
      }
    }

    > .filter-visibility {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -20px;
      right: 32px;
      font-size: 24px;
      color: ${ ({ theme }) => theme.colors.typography.medium };
      height: 40px;
      width: 40px;
      background: ${ ({ theme }) => theme.colors.white };
      border: 1px solid ${ ({ theme }) => theme.colors.grey.dark };
      cursor: pointer;
      border-radius: 50%;
    }

    @media (max-width: 736px) {
      padding: 0;
      min-height: 0;

      .container,
      .submit {
        display: none;
      }

      .submit {
        display: none;
      }

      &.closed {
        padding: 14px 16px 16px;
        min-height: auto;
        display: block;

        .container {
          display: block;

          .filter {
            flex: 1;
            max-width: 100%;
            margin-right: 0;
            margin-left: 0;
          }
        }

        .submit {
          display: flex;
        }
      }

      > .filter-visibility {
        display: none;
      }
    }
  }
  @media (max-width: 736px) {
    + .menu-overlay.visible {
      z-index: 900;
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      opacity: 0.7;
      background: ${ ({ theme }) => theme.colors.typography.darkest };
    }
  }

`
