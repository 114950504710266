import { ITheme } from '../../models'
import { BaseService } from './base'

class ConfigurationService extends BaseService {
  public getLoginTheme(id: string): Promise<ITheme> {
    return this.get(`entrypointconfiguration/${ id }`)
  }
}

export default new ConfigurationService()
