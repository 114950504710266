import { ITheme, Provider as ThemeProvider } from '@valudio/ui'
import React, { useState } from 'react'
import { Provider as IntlProvider } from 'react-intl-redux'
import { Store } from 'redux'
import { NotificationProvider } from '../../../context/notfication'
import { getLanguage, getTheme } from '../../../helpers/session'

interface IProps {
  store: Store
}

const Provider: React.FC<IProps> = ({ children, store }) => {
  const [ theme, setTheme ] = useState<ITheme>(getTheme())
  const [ language, setLanguage ] = useState<string>(getLanguage())

  store.subscribe(() => {
    setTheme(getTheme())
    setLanguage(getLanguage())
  })

  return (
    <IntlProvider store={ store }>
      <ThemeProvider theme={ theme } language={ language }>
        <NotificationProvider>
          { children }
        </NotificationProvider>
      </ThemeProvider>
    </IntlProvider>
  )
}

export default Provider
