import { SessionState } from '../states'
import { SessionActionTypes, SessionTypeKeys } from '../types'

const initialState: SessionState = null

export default (state: SessionState = initialState, action: SessionActionTypes): SessionState => {
  switch (action.type) {
    case SessionTypeKeys.SET_SESSION:
      return action.payload
    default:
      return state
  }
}
