import { Icon, IconName, ITableColumn, Pagination, Spinner, StatusDot, Table } from '@valudio/ui'
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { formatDate } from '../../../helpers/date'
import { TrackPage } from '../../../models'
import TrackFile from '../TrackFile'
import Styled from './styles'

interface IProps {
  page: TrackPage
  columns: ITableColumn[]
  onChangeTrackVisibility: (trackId: string) => void
  onPageChange: (pageNumber: number) => void
  isLoading: boolean
}

type Props = IProps & WrappedComponentProps

const TracksTable: React.FC<Props> = ({ page, columns, intl, onChangeTrackVisibility, onPageChange, isLoading }) => {
  const handleTrackVisibilityChange = (trackId: string) => {
    if (onChangeTrackVisibility) onChangeTrackVisibility(trackId)
  }

  const trackRows = page && page.items.map(x => {
    const statusColor = { backgroundColor: x.status.value.color }
    const iconClassNames = `archive-icon ${ !!x.visible && 'visible' }`
    const trackings = x.trackings.map(y => {
      const extension: string = y.extension.toLowerCase()

      if (extension === 'sap' || extension === 'idoc') {
        y.extension = 'sap'
      } else if (extension === 'excel' || extension === 'xls' || extension === 'csv') {
        y.extension = 'excel'
      } else if (
        extension === 'jpg' ||
        extension === 'jpeg' ||
        extension === 'png' ||
        extension === 'tif' ||
        extension === 'tiff'
      ) {
        y.extension = 'img'
      }

      return <TrackFile className="track-file" key={ y.fileId } trackFile={ y } />
    })

    return ({
      archived: (
        <Icon
          className={ iconClassNames}
          icon={ (!!x.visible ? 'eye' : 'hide') as IconName }
          onClick={ handleTrackVisibilityChange.bind(undefined, x.trackId) }
        />
      ),
      partner: x.partner,
      description: x.description,
      exchanges: trackings,
      date: formatDate(x.date),
      messageType: x.messageType.value,
      status: <StatusDot style={ statusColor } />
    })
  })

  const pagination = (page && !!page.items.length) && (
    <Pagination
      currentPage={ page.pagination.pageNumber }
      totalPages={ page.pagination.totalPages }
      onClick={ onPageChange }
    />
  )

  if (isLoading) return <Spinner />
  return (
    <Styled>
      <Table columns={ columns || [] } items={ trackRows || [] } fallbackMessage={ intl.formatMessage({ id: 'noTrackings' }) }/>
      { pagination }
    </Styled>
  )
}

export default  injectIntl(TracksTable)
