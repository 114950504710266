export function formatDate(value: string): string {
  const date = new Date(Date.parse(value))
  const year = date.getFullYear()
  const dateMonth = date.getMonth() + 1
  const month = dateMonth < 10 ? `0${ dateMonth }` : dateMonth
  const dateDay = date.getDate()
  const day = dateDay < 10 ? `0${ dateDay }` : dateDay
  const dateHours = date.getHours()
  const hours = dateHours < 10 ? `0${ dateHours }` : dateHours
  const dateMinutes = date.getMinutes()
  const minutes = dateMinutes < 10 ? `0${ dateMinutes }` : dateMinutes

  return `${ day }/${ month }/${ year } ${ hours }:${ minutes }`
}

export function formatInputDate(date: string | number): string {
  const dateObj = new Date(date)
  const year = dateObj.getFullYear()
  const dateMonth = dateObj.getMonth() + 1
  const month = dateMonth < 10 ? `0${ dateMonth }` : dateMonth
  const dateDay = dateObj.getDate()
  const day = dateDay < 10 ? `0${ dateDay }` : dateDay

  return `${ year }-${ month }-${ day }`
}

export function isValidDate(date: string | number): boolean {
  const dateObj = new Date(date)
  if (Object.prototype.toString.call(dateObj) === '[object Date]') {
    if (isNaN(dateObj.getTime())) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}
