import { combineReducers } from 'redux'
import intl from './intl'
import session from './session'
import theme from './theme'
import ui from './ui'

export default combineReducers({
  intl,
  session,
  theme,
  ui
})
