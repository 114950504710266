import { IOption, MultiSelect } from '@valudio/ui'
import React from 'react'
import Styled from './styles'

interface IProps {
  label: string
  items: any[]
  placeholder?: string
  onChange: (filters: any[]) => void
}

const MultiSelectFilter: React.FC<IProps> = props => {
  const { label, items, onChange, placeholder } = props
  const options: IOption[] = !!items && !!items.length
    ? items.map(i => {
        if ((typeof i.value) === 'object') return ({ id: i.key, label: i.value.text  })
        else return ({ id: i.key, label: i.value  })
      })
    : []

  return (
    <Styled className="filter">
      <label className="label">{ label }</label>
      <MultiSelect
        labelProp="label"
        placeholder={ placeholder }
        options={ options }
        onChange={ onChange }
        className="multi-select"
      />
    </Styled>
  )
}

export default MultiSelectFilter
